import styled from '@emotion/styled'

export const ModalContainer = styled.div(({ theme }) => ({
  zIndex: 1070,
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(50, 59, 75, 0.4)',
  backdropFilter: 'blur(5px)',
  overflowX: 'hidden',
  overflowY: 'auto',
}))

export const ModalWrapper = styled('div')<{ small?: boolean }>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 'calc(100% - (.5rem * 2))',
  position: 'relative',
  width: 'auto',
  margin: 8,
  padding: '16px 0',
  pointerEvents: 'none',
}))

export const ModalCard = styled('div', {
  shouldForwardProp: prop => prop !== 'small' && prop !== 'big',
})<{ small?: boolean,  big?: boolean }>(({ theme, small, big }) => ({
  pointerEvents: 'auto',
  background: '#fff',
  borderRadius: 16,
  overflow: 'auto',
  width: small ? 560 : (big ? 1200 : 800),
  maxWidth: 'calc(100% - 16px)',
  boxShadow:
    '0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 8px 12px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)',
  '& p': {
    marginBottom: 24,
  },
  '& li': {
    lineHeight: 1.4,
  },
}))

export const ContentWrapper = styled.div(({ theme }) => ({
  padding: 24,
  '& h2': {
    marginBottom: 8,
  },
}))

export const TopRow = styled.div(({ theme }) => ({
  display: 'flex',
  padding: '12px 24px',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '2px solid rgba(0, 0, 0, 0.1)',
  minHeight: 72,
  '& div': {
    cursor: 'pointer',
  },
}))

export const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  '& button, & a': {
    '& span': {
      fontSize: 14,
      lineHeight: 1.143,
    },
    '&:not(:first-child)': {
      marginLeft: 16,
      [theme.breakpoint.down('md')]: {
        marginLeft: 0,
        marginTop: 8,
      },
    },
    [theme.breakpoint.down('md')]: {
      width: '100%',
    },
  },
  [theme.breakpoint.down('md')]: {
    flexDirection: 'column',
  },
}))