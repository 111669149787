import Button from '@components/shared/Button'
import { useEventListener } from '@hooks/useEventListener'
import CloseIcon from '@icons/CloseIcon'
import { scrollLock } from '@utils/scrollLock'
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { ButtonWrapper, ContentWrapper, ModalCard, ModalContainer, ModalWrapper, TopRow } from './styles'
import { ModalProps } from './types'

const ModalV2 = ({
  contentNodes,
  title,
  content,
  buttons,
  open,
  onClose,
  children,
  topNodes,
  disableScroll,
  big,
  small,
}: ModalProps) => {
  const [modalRoot, setModalRoot] = useState<HTMLDivElement | null>(null)

  const onEscKeyDown = (event: Event | KeyboardEvent) => {
    if (event instanceof KeyboardEvent && event.key.toLowerCase() === 'escape') {
      onClose && onClose()
    }
  }

  useEffect(() => {
    if (open && disableScroll) {
      scrollLock.enable()
    } else {
      scrollLock.disable()
    }

    return () => {
      scrollLock.disable()
    }
  }, [open])

  useEventListener('keydown', onEscKeyDown)

  useEffect(() => {
    setModalRoot(document.querySelector<HTMLDivElement>('#modal-root'))
  }, [])

  if (!modalRoot || !open) {
    return null
  }

  return ReactDOM.createPortal(
    <ModalContainer onClick={() => onClose && onClose()}>
      <ModalWrapper>
        <ModalCard
          small={small}
          big={big}
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <TopRow>
            <h2>{title}</h2>
            {onClose && (
              <div onClick={() => onClose()}>
                <CloseIcon />
              </div>
            )}
          </TopRow>
          <ContentWrapper>
            {topNodes}
            {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
            {contentNodes}
            {buttons && (
              <ButtonWrapper>
                {buttons.map((button, index) => {
                  const clickProps = button.link
                    ? {
                        href: button.link,
                      }
                    : button.onClick
                    ? {
                        onClick: button.onClick,
                      }
                    : {}
                  return (
                    <Button {...clickProps} key={index} linkType={button.linkType} variant={button.variant}>
                      {button.label}
                    </Button>
                  )
                })}
              </ButtonWrapper>
            )}
            {children}
          </ContentWrapper>
        </ModalCard>
      </ModalWrapper>
    </ModalContainer>,
    modalRoot
  )
}

export default ModalV2
