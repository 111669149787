import { GatsbySSR } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import CookieWall from './src/components/marketing-website/CookieWall'
import { ConsentProvider } from './src/context/consent-context'

const ConsentScript = () => {
  const scriptContent = `
    (function() {
      function gtag() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(arguments);
      }

      var allowMarketing = false;
      var allowAnalytical = false;

      try {
        // Read consent settings from localStorage
        var consentJson = localStorage.getItem("consent");
        var consentData = consentJson ? JSON.parse(consentJson) : {};

        // Determine permissions (default to false/denied if missing)
        allowMarketing = consentData.marketing === true;
        allowAnalytical = consentData.analytical === true;
      } catch (e) {
        console.error("Error processing consent data, defaulting to 'denied'", e);
        // Ensure all are denied in case of failure
        allowMarketing = false;
        allowAnalytical = false;
      }

      // Set Google Tag Manager consent
      gtag('consent', 'default', {
        ad_storage: allowMarketing ? 'granted' : 'denied',
        ad_user_data: allowMarketing ? 'granted' : 'denied',
        ad_personalization: allowMarketing ? 'granted' : 'denied',
        analytics_storage: allowAnalytical ? 'granted' : 'denied',
      });

      window.uetq = window.uetq || [];
      window.uetq.push('consent', 'default', {
        'ad_storage': allowMarketing ? 'granted' : 'denied',
      });
    })();
  `

  return (
    <Helmet>
      <script>{scriptContent}</script>
    </Helmet>
  )
}

export const wrapPageElement: GatsbySSR['wrapPageElement'] = ({ element, props }) => {
  return (
    <ConsentProvider>
      <ConsentScript />
      <CookieWall />
      {element}
    </ConsentProvider>
  )
}
