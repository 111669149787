import { ModalCard } from '@components/shared/Modal/styles'
import { ContentWrapper, ModalWrapper, TopRow } from '@components/shared/ModalV2/styles'
import styled from '@emotion/styled'

export const WallModalWrapper = styled(ModalWrapper)(({ theme }) => ({
  [theme.breakpoint.down('md')]: {
    height: '100%',
    marginTop: 0,
    marginBottom: 0,
  },
}))

export const WallTopRow = styled(TopRow)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  border: 0,

  '& > button': {
    position: 'absolute',
    left: 24,

    [theme.breakpoint.down('md')]: {
      width: 32,
      '& > div': {
        position: 'absolute',
        left: 9,
      },
      '& > span': {
        display: 'none',
      },
    },
  },
}))

export const WallModalCard = styled(ModalCard)(({ theme }) => ({
  maxWidth: 620,

  [theme.breakpoint.down('md')]: {
    maxHeight: '96%',
  },
}))

export const WallContentWrapper = styled(ContentWrapper)(({ theme }) => ({
  paddingTop: 8,
  paddingBottom: 0,

  '& > h2': {
    marginBottom: 24,
    textAlign: 'center',
  },
  '& > p': {
    fontSize: theme.fontSizes.desktop.small,
    marginBottom: 0,
  },
  '& > b': {
    display: 'block',
    marginTop: 24,
    marginBottom: 8,
  },
}))

export const WallSettings = styled.div(({ theme }) => ({
  borderRadius: 4,
  marginTop: 24,
  boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1)',
  background: '#e9e9e942',

  '& > div': {
    position: 'relative',
    padding: 16,
    borderBottom: '2px solid rgba(0, 0, 0, 0.1)',

    '&:last-child': {
      borderBottom: 0,
    },
    '& > h4': {
      margin: 0,
      marginBottom: 16,
    },
    '& > p': {
      fontSize: theme.fontSizes.desktop.small,
      marginBottom: 0,

      '& > span': {
        color: '#297AFF',
        cursor: 'pointer',
      },
    },
    '& > div': {
      position: 'absolute',
      top: 10,
      right: 16,

      display: 'flex',
      gap: 16,
      alignItems: 'center',
    },
  },
}))

export const WallFooter = styled('div', {
  shouldForwardProp: prop => prop !== 'settings',
})<{ settings?: boolean }>(({ theme, settings }) => ({
  display: 'flex',
  gap: 16,
  flexWrap: settings ? 'wrap' : 'initial',
  margin: '24px -10px 0',
  padding: '0 10px 24px',
  background: theme.colors.N0,

  [theme.breakpoint.down('md')]: {
    position: 'sticky',
    bottom: 0,

    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: -24,
      right: 0,
      left: 0,
      height: 24,
      backgroundImage: 'linear-gradient(#ffffff00, white)',
    },
  },

  '& > button': {
    flex: settings ? 1 : 'initial',
    transition: 'background-color 400ms ease!important',

    '&:first-child': {
      flex: 1,
    },
    '&:last-child': {
      flex: settings ? '0 0 100%' : 'initial',
    },
  },
}))
