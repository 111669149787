import { ModalProps } from '@components/shared/ModalV2/types'
import { graphql, useStaticQuery } from 'gatsby'
import { Dispatch, useState } from 'react'

type DisclaimerModalTypeProps =
  | 'regular_general_conditions'
  | 'regular_privacy_policy'
  | 'orange_case_privacy_policy'
  | 'orange_case_general_conditions'
  | null

const useDisclaimerModal = (): [setModalType: Dispatch<DisclaimerModalTypeProps>, modalProps: ModalProps] => {
  const [modalType, setModalType] = useState<DisclaimerModalTypeProps>(null)

  const { strapiSetting } = useStaticQuery(graphql`
    query {
      strapiSetting {
        regular_general_conditions
        regular_privacy_policy
        orange_case_general_conditions
        orange_case_privacy_policy
      }
    }
  `)

  const getModalCopy = () => {
    if (modalType === 'orange_case_privacy_policy') {
      return strapiSetting?.orange_case_privacy_policy
    } else if (modalType === 'orange_case_general_conditions') {
      return strapiSetting?.orange_case_general_conditions
    } else if (modalType === 'regular_general_conditions') {
      return strapiSetting?.regular_general_conditions
    } else if (modalType === 'regular_privacy_policy') {
      return strapiSetting?.regular_privacy_policy
    }
    return strapiSetting?.regular_general_conditions
  }

  const getModalTitle = () => {
    if (modalType?.includes('privacy')) {
      return 'Privacyverklaring'
    }
    return 'Algemene voorwaarden'
  }

  return [
    setModalType,
    {
      title: getModalTitle(),
      open: modalType !== null,
      content: getModalCopy(),
      disableScroll: true,
      onClose: () => setModalType(null),
    },
  ]
}

export default useDisclaimerModal
